import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { User } from '@firebase/auth-types';

import { BehaviorSubject, firstValueFrom, take } from 'rxjs';

import * as Sentry from '@sentry/browser';

import CryptoES from 'crypto-es';
import { nanoid } from 'nanoid';

import { AfsService } from '@services/afs.service';
import { AlertService } from '@services/alert.service';
import { AnalyticsService } from '@services/analytics.service';
import { ChannelsService } from '@services/channels.service';
import { ConnectService } from '@services/connect.service';
import { DtService } from '@services/dt.service';
import { EnvService } from '@services/env.service';
import { ErrorService } from '@services/error.service';
import { FunctionsService } from '@services/functions.service';
import { LsService } from '@services/ls.service';
import { NavigateService } from '@services/navigate.service';
import { OtpService } from '@services/otp.service';
import { SettingsService } from '@settings/settings.service';
import { SignoutService } from '@services/signout.service';
import { SourcesService } from '@services/sources.service';
import { UtilitiesService } from '@services/utilities.service';

import { ClientSettings, CLIENT_SETTINGS_VERSION } from '@shared/settings.interface';
import { PendingUser, UserSettings } from '@shared/user.interface';
import { RequestAddConnection } from '@shared/request.interface';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private signingInSubject$ = new BehaviorSubject<boolean>(false);

  private authStateChanged = false;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AfsService,
    private alert: AlertService,
    private analytics: AnalyticsService,
    private appState: AppState,
    private channels: ChannelsService,
    private connect: ConnectService,
    private dt: DtService,
    private env: EnvService,
    private error: ErrorService,
    private functions: FunctionsService,
    private ls: LsService,
    private navigate: NavigateService,
    private otp: OtpService,
    private router: Router,
    private settings: SettingsService,
    private signout: SignoutService,
    private sources: SourcesService,
    private userState: UserState,
    private util: UtilitiesService,
  ) {
    const encryptedParams = this.ls.get('redirect', '');
    this.ls.set('redirect', '');
    if (encryptedParams) {
      this.alert.loadingMessage('Signing in...');
    }

    void this.afAuth.onAuthStateChanged(async user => {
      this.authStateChanged = true;

      // Refresh custom claims for user
      const tokenResult = user ?
        await user.getIdTokenResult()
          .catch((error) => console.error(`auth.service getIdTokenResult promise failed: ${error}`)) : undefined;
      const clientID = tokenResult?.claims?.clientID;

      if (user) {
        this.userState.setUser(user, clientID);
        await this.userState.initSettings();

        if (!this.signingInSubject$.getValue()) {
          await this.updateAccount(user, clientID);
        }

        Sentry.setUser({
          'id': user.uid,
          'email': user.email ?? '',
          'username': user.displayName ?? '',
        });
      }

      if (encryptedParams) {
        const userCredential = await firstValueFrom(this.afAuth.credential);
        const decrypted = CryptoES.AES.decrypt(encryptedParams, 'e9bbab78').toString(CryptoES.enc.Utf8);

        // Google/Facebook accounts must have an email address
        if (userCredential?.user && !userCredential?.user?.email && !userCredential?.user?.emailVerified) {
          const source = decrypted.split('|')[3] ?? 'Google/Facebook';
          const otherSource = source === 'Facebook' ? 'Google' : source === 'Google' ? 'Facebook' : 'different';
          await this.alert.confirm('Email Address Required',
            `No email address is associated with this ${source} account.\n\nPlease use a ${otherSource} account ` +
            `or enter an email address and password.`, 'error', 'Try Again');
          this.ls.set('defaultSignin', '0');
          await this.router.navigate(['signin'], { queryParams: { page: 'register' } });
        } else if (userCredential?.user) {
          const sourceID = decrypted.split('|')[0];
          const merchantID = decrypted.split('|')[1];
          const merchantCode = decrypted.split('|')[2];
          if (userCredential.additionalUserInfo?.isNewUser) {
            await this.sendEmailVerification(userCredential?.user);
            await this.createAccount(userCredential.user, sourceID, merchantID, merchantCode);
          } else {
            await this.updateAccount(userCredential.user);
            if (sourceID && merchantID && merchantCode) {
              await this.addConnection(sourceID, clientID, '', '', merchantID, merchantCode);
            }
          }
          this.signingInSubject$.next(false);
          await this.routeNext(userCredential.user.emailVerified);
        }
        this.alert.loadingMessage('');
      }
    });
  }

  ngOnDestroy(): void {
    this.signout.complete();
  }

  // ----------------------
  // ACCOUNT FUNCTIONS
  // ----------------------

  private async createAccount(user: User, sourceID = '??', merchantID?: string, merchantCode?: string): Promise<void> {
    this.alert.loadingMessage('Creating account...', 2, 30);

    // Check if user is part of an existing account
    if (await this.checkForPendingUser(user)) return;

    const clientID = nanoid(9);
    const locationID = nanoid(9);
    const connectionID = nanoid(9);

    sourceID = sourceID || '??';

    // Create new business/location/connection records
    const channels = [];
    const locationChannels = [];
    if (sourceID !== '??') {
      const channelIDs = this.sources.getSetting(sourceID, 'channelIDs') as string[];
      for (const channelID of channelIDs) {
        channels.push({
          channelID: channelID,
          color: this.channels.setColor(channelID),
        });
        locationChannels.push({
          channelID: channelID,
          servchgs: false,
          commission: 0,
        });
      }
    }

    const newBusiness: ClientSettings = {
      _version: CLIENT_SETTINGS_VERSION,
      _connectionMaps: sourceID && merchantID && merchantCode ? [`${sourceID}|${merchantID}`] : [],
      _timezones: [],
      businessName: '',
      startDayOfWeek: 1,
      locations: [],
      channels: channels,
      license: {
        billingAgent: ['CL', 'SH'].includes(sourceID) ? sourceID : '',
        licenseID: ['CL', 'SH'].includes(sourceID) && merchantID ? merchantID : 'UNLICENSED',
        status: 'inactive',
      },
    };

    newBusiness.locations[0] = {
      locationID: locationID,
      active: true,
      timezone: '',
      dayEndHour: 0,
      connections: [],
      channels: locationChannels,
    };

    newBusiness.locations[0].connections = [{
      connectionID: connectionID,
      sourceID: sourceID,
      active: !!(merchantID && merchantCode),
      id: merchantID ?? '',
      token: merchantCode ?? '',
      lastChecked: this.dt.oldestDate,
    }];

    if (user.email?.slice(0, 8) !== 'sroth720') {
      console.error(`New account created for ${sourceID}: ${user.email}, ${clientID}, ${user.uid}`);
    }

    // Add clientID to user settings, as well as in custom claims for use in Firestore Rules
    try {
      const userSettings: UserSettings = {
        clientID: clientID,
        email: user.email ?? '',
        name: user.displayName ?? '',
      };
      await this.functions.updateUser(user.uid, userSettings);
      await user.getIdTokenResult(true);  // Refresh custom claims for user
    } catch (error) {
      console.error(`APP Auth error updating user: ${user.uid}, ${error}`);
    }

    if (user.email?.slice(0, 8) !== 'sroth720') {
      console.info(`New user created for ${sourceID}: ${user.email}, ${clientID}, ${user.uid}`);
    }

    if (user.email?.slice(0, 8) !== 'sroth720') {
      console.info('New account record', JSON.stringify(newBusiness));
    }

    // Create client record in Firestore
    try {
      await this.afs.setDocument('clients', clientID, newBusiness, { merge: false });
    } catch (error) {
      console.error(`APP Auth error updating clients: ${clientID}, ${error}, ${JSON.stringify(newBusiness)}`);
    }

    if (user.email?.slice(0, 8) !== 'sroth720') {
      console.info(`New client record created for ${sourceID}: ${user.email}, ${clientID}, ${user.uid}`);
    }

    // Update account record and get settings
    await this.updateAccount(user, clientID);

    // Request back-end to finish Clover/Shopify setup and request data
    if (sourceID === 'SH' && merchantID && !merchantCode) {
      await this.connect.addShopifyConnection(merchantID);
    } else if (sourceID && merchantID && merchantCode) {
      await this.addConnection(sourceID, clientID, locationID, connectionID, merchantID, merchantCode);
    }

    this.alert.loadingMessage();
  }

  private async checkForPendingUser(user: User): Promise<boolean> {
    if (!user.email) return false;

    const pendingUser = await this.afs.getDocument('pendingUsers', user.email, true) as PendingUser;
    if (!pendingUser) return false;

    const userSettings: UserSettings = {
      clientID: pendingUser._clientID,
      email: user.email,
      name: user.displayName ?? '',
      locationIDs: pendingUser.locationIDs ?? [],
      billing: pendingUser.billing ?? false,
      readonly: pendingUser.readonly ?? false,
    };

    // Create user in backend and refresh user settings
    await this.functions.updateUser(user.uid, userSettings);
    await user.getIdTokenResult(true);  // Refresh custom claims for user
    await this.userState.initSettings();

    // Update account record and get settings
    await this.updateAccount(user, pendingUser._clientID);

    // Remove pending user
    await this.afs.deleteDocument('pendingUsers', user.email);

    return true;
  }

  private async updateAccount(user: User, clientID?: string): Promise<void> {
    if (!clientID) {
      let result = await user.getIdTokenResult(false);
      if (!result.claims.clientID) {
        // Refresh the token if the required field (clientID) is missing
        result = await user.getIdTokenResult(true);
      }
      clientID = result.claims.clientID;
    }

    if (clientID) {
      this.appState.clientID = clientID;
      this.settings.getClientSettings$(clientID);
      await this.analytics.login(user);
    }
  }

  private async addConnection(sourceID: string, clientID: string, locationID: string, connectionID: string,
    merchantID: string, merchantCode: string): Promise<void> {

    const updateOnly = !!merchantCode && !locationID && !connectionID;

    if (!locationID || !connectionID) {
      const settings = await this.afs.getDocument<ClientSettings>('clients', clientID);
      if (settings) {
        for (const location of settings.locations) {
          for (const connection of location.connections) {
            if (connection.id === merchantID) {
              locationID = location.locationID;
              connectionID = connection.connectionID;
              break;
            }
          }
        }
      }
      if (!locationID || !connectionID) {
        console.error(`APP auth.addConnection: Unable to find locationID or connectionID for ${merchantID}`);
        return;
      }
    }

    this.alert.loadingMessage(`Creating connection to ${this.sources.getSetting(sourceID, 'name')}...`, 3, 20);
    if (!merchantCode) {
      console.error(`Missing merchant code in auth.addConnection for ${clientID}|${locationID}`);
      this.alert.loadingMessage();
      return;
    }

    const message: RequestAddConnection = {
      hostname: this.env.databaseURL,
      sourceID, clientID, locationID, connectionID, merchantID, merchantCode, updateOnly,
    };
    await this.functions.addConnection(message);
    this.alert.loadingMessage();
  }

  // ----------------------
  // REGISTRATION FUNCTIONS
  // ----------------------

  public async registerWithEmail(email: string, password: string, sourceID?: string, merchantID?: string,
    merchantCode?: string): Promise<void> {

    this.signingInSubject$.next(true);
    try {
      const userCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      if (user) {
        await this.sendEmailVerification(user);
        await this.createAccount(user, sourceID, merchantID, merchantCode);
        this.signingInSubject$.next(false);
        await this.routeNext(user.emailVerified);
      }
    } catch (error) {
      this.signingInSubject$.next(false);
      if ((error as firebase.auth.AuthError).code === 'auth/email-already-in-use') {
        await this.signinWithEmail(email, password, sourceID, merchantID, merchantCode, true);
      } else {
        await this.handleAuthError(error as firebase.auth.AuthError, 'register');
      }
    }
  }

  // ----------------------
  // SIGNIN FUNCTIONS
  // ----------------------

  public async signinWithGoogle(sourceID: string, merchantID: string, merchantCode: string, email?: string):
    Promise<void> {

    this.alert.loadingMessage('Connecting to Google...');
    this.signingInSubject$.next(true);
    try {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      if (email) {
        googleAuthProvider.setCustomParameters({ 'login_hint': email });
      }
      const params = `${sourceID}|${merchantID}|${merchantCode}|Google`;
      const encryptedParams = CryptoES.AES.encrypt(params, 'e9bbab78').toString();
      this.ls.set('redirect', encryptedParams);
      await firebase.auth().signInWithRedirect(googleAuthProvider);
    } catch (error) {
      this.alert.loadingMessage('');
      this.signingInSubject$.next(false);
      await this.handleAuthError(error as firebase.auth.AuthError, 'signin');
    }
  }

  public async signinWithFacebook(sourceID: string, merchantID: string, merchantCode: string): Promise<void> {
    this.alert.loadingMessage('Connecting to Facebook...');
    this.signingInSubject$.next(true);
    try {
      const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
      const params = `${sourceID}|${merchantID}|${merchantCode}|Facebook`;
      const encryptedParams = CryptoES.AES.encrypt(params, 'e9bbab78').toString();
      this.ls.set('redirect', encryptedParams);
      await firebase.auth().signInWithRedirect(facebookAuthProvider);
    } catch (error) {
      this.alert.loadingMessage('');
      this.signingInSubject$.next(false);
      await this.handleAuthError(error as firebase.auth.AuthError, 'signin');
    }
  }

  public async signinWithEmail(email: string, password: string, sourceID?: string, merchantID?: string,
    merchantCode?: string, registerFailed?: boolean): Promise<void> {

    this.signingInSubject$.next(true);
    try {
      const userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
      if (userCredential.user) {
        if (userCredential.additionalUserInfo?.isNewUser) {
          await this.createAccount(userCredential.user, sourceID, merchantID, merchantCode);
        } else {
          await this.updateAccount(userCredential.user);
          if (sourceID === 'SH' && merchantID && !merchantCode) {
            await this.connect.addShopifyConnection(merchantID);
          } else if (sourceID && merchantID && merchantCode) {
            await this.addConnection(sourceID, this.appState.clientID, '', '', merchantID, merchantCode);
          }
        }
        this.signingInSubject$.next(false);
        await this.routeNext(userCredential.user.emailVerified);
      }
    } catch (error) {
      this.signingInSubject$.next(false);
      this.error.handlerCompleteSubject$.pipe(take(1)).subscribe(async () => {
        if (['auth/user-not-found', 'auth/invalid-login-credentials'].includes((error as firebase.auth.AuthError).code)) {
          this.ls.set('defaultSignin', '0');
          const response = await this.alert.stayOrRoute('Account Not Found',
            'No account was found for these credentials. Please make sure you entered them correctly.\n\n' +
            'Tap the button below to create a new account with these credentials.',
            'error', 'Try Again', 'Create Account');
          if (response.isConfirmed) {
            await this.registerWithEmail(email, password, sourceID, merchantID, merchantCode);
          }
        } else {
          const provider = await this.afAuth.fetchSignInMethodsForEmail(email);
          await this.handleAuthError(error as firebase.auth.AuthError, registerFailed ? 'register' :
            'signin', email, provider, sourceID, merchantID, merchantCode);
        }
      });
    }
  }

  private async routeNext(emailVerified: boolean): Promise<void> {
    if (this.appState.clientID && await this.getUser()) {
      this.ls.set('defaultSignin', '1');
      if (!emailVerified) {
        await this.router.navigate(['verify-email']);
      } else {
        this.alert.loadingMessage('Signing in...');
        await this.navigate.home();
      }
    } else {
      await this.router.navigate(['settings']);
    }
  }

  public async getSigninMethod(email: string): Promise<string[]> {
    return await this.afAuth.fetchSignInMethodsForEmail(email);
  }

  public async resetPassword(email: string): Promise<string> {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
      return '';
    } catch (error) {
      return (error as firebase.auth.AuthError).code;
    }
  }

  public async updateAuthPersistence(persistence: string): Promise<void> {
    if (persistence === 'session') {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    } else {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    }
  }

  private async sendEmailVerification(user?: User | null): Promise<void> {
    user = user ?? await this.afAuth.currentUser;
    if (!user?.email) {
      throw new Error(`APP auth.sendEmailVerification: User email address not defined for ${JSON.stringify(user)}`);
    }

    this.alert.loadingMessage('Sending verification passcode via email...', 3, 30);
    const passcode = await this.otp.getPasscode(user.email);
    await this.otp.emailPasscode(user.email, passcode);
  }

  // ----------------------
  // MISC PUBLIC FUNCTIONS
  // ----------------------

  public async getUser(): Promise<User | null> {
    // Wait for auth state before checking login status
    while (!this.authStateChanged) {
      await this.util.sleep(0.1);
    }
    return await this.afAuth.currentUser;
  }

  public async reloadUser(): Promise<void> {
    const user = await this.afAuth.currentUser;
    if (user) await user.reload();
  }

  public async signOut(): Promise<void> {
    this.alert.loadingMessage();  // Dismiss message overlay

    // Unsubscribe from all observables
    this.signout.next();
    this.signout.complete();
    this.appState.resetState();
    this.userState.resetState();

    // Signout from Firebase Auth
    await this.userSignout();

    // Reload to clear everything and return to Signin page
    window.location.reload();
  }

  public async userSignout(): Promise<void> {
    await this.afAuth.signOut();
  }

  // ----------------------
  // INTERNAL FUNCTIONS
  // ----------------------

  private async handleAuthError(error: firebase.auth.AuthError, type: string, email?: string,
    provider?: string[], sourceID?: string, merchantID?: string, merchantToken?: string): Promise<void> {

    this.alert.loadingMessage();
    switch (error.code) {
      case 'auth/wrong-password': {
        if (provider?.[0] === 'facebook.com') {
          const response = await this.alert.stayOrRoute('Account Already Exists',
            `A Facebook account already exists for email address <b>${email}</b>.\n\n` +
            `Sign in using Facebook to access this existing account, or ` +
            `use a different email address to create a new account.`,
            'info', 'Try Again', 'Sign In Using Facebook');
          if (response.isConfirmed) {
            await this.signinWithFacebook(sourceID ?? '', merchantID ?? '', merchantToken ?? '');
          }
        } else if (provider?.[0] === 'google.com') {
          const response = await this.alert.stayOrRoute('Account Already Exists',
            `A Google account already exists for email address <b>${email}</b>.\n\n` +
            `Sign in using Google to access this existing account, or ` +
            `use a different email address to create a new account.`,
            'info', 'Try Again', 'Sign In Using Google');
          if (response.isConfirmed) {
            await this.signinWithGoogle(sourceID ?? '', merchantID ?? '', merchantToken ?? '', email);
          }
        } else if (type === 'register') {
          await this.alert.message('Account Already Exists',
            `An account already exists for email address ${email}.\n\nSign in with the correct password or ` +
            `use another email address to create an account.`,
            'error', 'Try Again');
        } else {
          await this.alert.message('Incorrect Password',
            'The password is incorrect. Please make sure you entered it correctly.',
            'error', 'Try Again');
        }
        break;
      }
      case 'auth/email-already-in-use': {
        await this.alert.TryAgainOrSignin('Account Already Exists',
          'An account already exists for this email address.\n\nTry using a different email address, or tap ' +
          'the button below to sign in to this existing account.',
          'error', 'Try Again', 'Sign In');
        break;
      }
      case 'auth/account-exists-with-different-credential': {
        await this.alert.message('Account Already Exists',
          `An account already exists for email address <b>${error.email}</b>.\n\n` +
          `Sign in with this email address instead of signing in using Facebook.`,
          'error', 'Try Again',
        );
        break;
      }
      default: {
        console.error('Signin error', error, error.code);
        await this.alert.message('Signup/Signin Error',
          'An unexpected problem occurred while signing up.',
          'error', 'Try Again',
        );
        break;
      }
    }
  }
}
